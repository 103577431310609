import { Container, Divlogo, TopHeader, Branco, Logo, ButtonMenu, Bar, Blur } from './styles';

import LogoSVG from '../../assets/SVG/logo-match.svg'
import { useState } from 'react';

import Menu from './Menu';

function Header() {
  const [showModal, setShowModal] = useState(false);
  const [toggleBurger, setToggleBurger] = useState(false);

  function handleClick() {
    setShowModal(!showModal)
    setToggleBurger(!toggleBurger)

  }

  function handleClose() {
    setShowModal(!showModal)
    setToggleBurger(false)
  }

  function BackTop() {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Container>
      <Divlogo><Logo onClick={BackTop} src={LogoSVG} /></Divlogo>
      <TopHeader>
      <Branco/>
        <Blur />
        <ButtonMenu onClick={(e) => handleClick()}>
          <Bar className={toggleBurger ? "bar1" : "bar4"} />
          <Bar className={toggleBurger ? "bar2" : "bar5"} />
          <Bar className={toggleBurger ? "bar3" : "bar6"} />
        </ButtonMenu>
        {showModal ?
          <Menu onClose={handleClose} />
          : null}
      </TopHeader>
    </Container>
  );
};

export default Header;
