import { useRef, useState } from 'react'
import * as Yup from 'yup'
import InputMask from 'react-input-mask'
import ReCAPTCHA from "react-google-recaptcha"

import { SubmitHandler, FormHandles } from '@unform/core'

import getValidationErrors from '../../../utils/getValidationErrors'

import * as S from '../Enviado/styles'

import {
  Container,
  Closeicon,
  Numerocontato,
  Main,
  Text,
  Input,
  Select,
  Textarea,
  Containerfooter,
  Botao,
  Politicaeprivacidade,
  Termos,
  Checkbox,
  Checktermos,
  Form,
  ButaoEnviar,
  Blue,
  Branco,
  TextMotivo
} from './styles';

import api from '../../../services/api'

interface Lead {
  name: string;
  email: string;
  message: string;
  motivation: string;
  term: string;
  tel: string;
}

function FormContato({ onClose = () => { } }) {
  const [submited, setSubmited] = useState(false);
  const [acceptState, setAcceptState] = useState(false);
  const [captchaState, setCaptchaState] = useState(false);
  const [nameState, setNameState] = useState(false);
  const [emailState, setEmailState] = useState(false);

  const formRef = useRef<FormHandles>(null)
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const motivations = [
    {
      label: "Quero agendar uma reunião",
      value: "Quero agendar uma reunião"
    },
    {
      label: "Parcerias e eventos",
      value: "Parcerias e eventos"
    },
    {
      label: "Outros assuntos",
      value: "Outros assuntos"
    }
  ]


  const sendLead = (data: Lead) => {
      //TODO incluir recurso
      api.post('https://api.match.mt/lead_site', data).then((response => {
        console.log('dados de retorno', response);
      }))
  }

  const handleSubmit: SubmitHandler<FormData> = async (data: any) => {
    const formData = { "name": data.name, "email": data.email, "message": data.message, "term": data.term, "motivation": data.term, "tel": data.tel, "business": data?.business }

    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Informe o seu nome'),
        email: Yup.string().required('Informe o seu email'),
        message: Yup.string().required('Insira uma mensagem'),
        motivation: Yup.string().required('Insira o motivo do contato'),
        term: Yup.string().required('Aceite o termo'),
        tel: Yup.string().required('Insira o telefone'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setSubmited(true)
      sendLead(formData)
      document.body.style.overflow = 'hidden';
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        setSubmited(true)
        document.body.style.overflow = 'hidden';
        return;
      }
    }
  }

  const handleName = (e: any) => {
    let completeName = /^[a-z]+(\s[a-z]+)?$/i.test(e.target.value)
    let minLetters = e.target.value.length > 3

    setNameState(minLetters && completeName)
  }

  const handleEmail = (e: any) => {
    let validEmail = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+(\.[a-z]+)?$/i.test(e.target.value)
    setEmailState(validEmail)
  }

  const handleChebox = (e: any) => {
    setAcceptState(e.target.checked)
  }

  const handleRecaptcha = (e: any) => {
    setCaptchaState(e.length > 0)
  }

  const handleCloseForm = () => {
    setSubmited(false)
    onClose()
    document.body.style.overflow = 'auto';
  }

  return (
    <Container>
       <Branco/>
      <Main>
        <Closeicon onClick={onClose} />
        {submited ?
          <S.Container>
            <S.Closeicon onClick={() => handleCloseForm()} />
            <S.Title>Recebemos a sua mensagem! Em breve, entraremos em contato para marcar um café. Até logo, match.</S.Title>
          </S.Container>

          : <Form ref={formRef} onSubmit={handleSubmit}>
            <Numerocontato>
              <text className='Vamos'>Vamos conversar?</text>
              <br />
              <text className='Apresentaçao'>Conheça mais sobre nossos serviços e soluções inovadoras para o mercado. A <Blue>match</Blue> está pronta para essa parceria!</text>
            </Numerocontato>
            <Text>
              <Input name="name" id="errorName" placeholder="Escreva o seu nome" label="Nome" type="text" onChange={handleName}/>
              {!nameState && <span className="Error">*Insira seu nome</span>}
            </Text>
            <Text>
              <Input name="email" placeholder="Coloque o seu principal endereço de e-mail" label="Email" type="email" required onChange={handleEmail}/>
              {!emailState && <span className="Error">*Insira um e-mail válido</span>}
            </Text>
            <Text>
              <InputMask mask="(99) 99999-9999" >
                {() => (
                  <Input name="tel" placeholder="Digite o seu número de telefone" label="Telefone" />
                )}
              </InputMask>
            </Text>
            <Text>
              <Input name="business" placeholder="Em que empresa você trabalha?" label="Empresa" type="text" />
            </Text>
            <TextMotivo>
            Motivo
            <Select options={motivations} name="motivation" label=" " placeholder="Selecione o motivo do contato" />
            </TextMotivo>
            <Text>
              <Textarea name="message" placeholder="Conte um pouco sobre o que você gostaria de conversar com a match" label="Fale com a gente" />
            </Text>
            <Containerfooter>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lddrs0bAAAAAPnjOvjDvYuiL0QM1SD5dy8-s521"
                onChange={(e) => handleRecaptcha(e)}
              />
              <ButaoEnviar>
                <Botao type="submit" disabled={!(acceptState && captchaState && nameState && emailState)}>
                  Enviar Mensagem
                </Botao>
              </ButaoEnviar>
            </Containerfooter>
            <Checktermos>
              <Checkbox onChange={(e) => handleChebox(e)} type="checkbox" id="Termos e privaciade" value="accepted" name="term" />
              <Politicaeprivacidade>Estou de acordo com a Política de Privacidade da Match. Para conferir  <Termos href="videos/POLITICAP.pdf" target="_blank" rel='noopener noreferrer' >Clique aqui</Termos></Politicaeprivacidade>
            </Checktermos>
          </Form>}
      </Main>
    </Container>
  );
};

export default FormContato;
