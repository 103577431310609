import AnchorLink from 'react-anchor-link-smooth-scroll'

import { Apresentaçao, Container, Descriçao, Divbuttons, Butaoum, Butaodois, Imagemum, Imagemdois, Imagemtres, Blue } from './styles'

import Imagem_1 from '../../assets/SVG/form3-2.svg';
import Imagem_2 from '../../assets/SVG/form2-2.svg';
import Imagem_3 from '../../assets/SVG/form1-2.svg';
import FormContato from './FormContato';

import { useContato } from '../../contexts/contato';

function Home() {
  const { showModalContact, handleModalContact } = useContato();

  return (
    <Container>
      <Imagemum src={Imagem_1} />
      <Imagemdois src={Imagem_3} />
      <Imagemtres src={Imagem_2} />

      <Apresentaçao>
        <text>Somos a <Blue>match</Blue>:</text>
        <br />
        <text>experts em construir relações
        <br/>
        a longo prazo.</text>
      </Apresentaçao>

      <Descriçao>
        <text>Entre matchers e clientes.</text>
        <br />
        <text>Entre pessoas e marcas.</text>
        <br />
        <text>Entre equipes multidisciplinares e tecnologias.</text>
        <br />
        <text>Entre novos talentos e o mercado.</text>
      </Descriçao>

      <Divbuttons>
        <Butaoum>
          <AnchorLink href='#o-que-entregamos'><button className='ButtonMaisDetalhes'>Conheça a match</button></AnchorLink>
        </Butaoum>
        <Butaodois>
          <button
            onClick={handleModalContact}
            className='ButtonContato'>Fale com a gente</button>
        </Butaodois>
      </Divbuttons>
      {showModalContact ?
        <FormContato onClose={handleModalContact} />
        : null}
    </Container>
  );
};

export default Home;
