import React from 'react';
import { render, hydrate } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { PropostaProvider } from './contexts/proposta';
import { ContatoProvider } from './contexts/contato';

import App from './App';
import Error from './pages/Error';
import Blog from './pages/Blog';
import Posts from './pages/Posts';
import Sflive2023 from './pages/Sflive2023';

const rootElement = document.getElementById('root')

const structure = (
  <BrowserRouter>
    <React.StrictMode>
      <PropostaProvider>
        <ContatoProvider>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/error" element={<Error />} />
            <Route path="/blog" element={<Blog />} />
            <Route path={"/blog/:topicId"} element={<Posts />} />
            <Route path={"/sflive2023"} element={<Sflive2023 />} />
          </Routes>
        </ContatoProvider>
      </PropostaProvider>
    </React.StrictMode>
  </BrowserRouter>
)

if (rootElement && rootElement.hasChildNodes()) {
  hydrate(structure, rootElement)
} else {
  render(structure, rootElement)
}
