import styled from 'styled-components';

import AnchorLink from 'react-anchor-link-smooth-scroll';

export const Container = styled.div`
        width: 100%;
        
`;

export const Elementos = styled.div`

@media (max-width: 480px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
`;

export const Rodape = styled.footer`
    width: 100%;
    height: 150px;
    background: var(--unnamed-color-121212) 0% 0% no-repeat padding-box;
    background: #121212 0% 0% no-repeat padding-box;
    opacity: 1;
    display: inline-block;

    @media (max-width: 480px) {
       display: block;
       position: absolute;
       align-items: center;
       justify-content: center;
       width: 100%;
       height: 414px;
    }

    
`;
export const Simbolo = styled.img`
    width: 46px;
    height: 35px;
    margin-top: 32px;
    margin-left: 318px;

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-top: 32px;
        margin-left: 41px;
        width: 46px;
        height: 35px;

  }
    @media (max-width: 480px) {
        margin-top: 32px;
        margin-left: 50%;
        transform: translateX(-50%);
        width: 85px;
        height: 64px;
    }
`;

export const DescriçaoFooter = styled.div` 
    margin-left: 413px;
    margin-top: -45px;
    display: block;

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-top: -45px;
        margin-left: 136px;
        width: 317px;
        height: 67px;

  }
    @media (max-width: 480px) {
        text-align: center;
        margin-top: 30px;
        margin-left: 50%;
        transform: translateX(-50%);
        width: 296px;
        height: 67px;

    }
`;

export const Endereco = styled.div`
    margin-top: -70px;
    margin-left: 1190px;
    width: 317px;
    height: 43px;
    color: #ffffff;
    opacity: 0.3;
    line-height: 24px;

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-left: 913px;
        margin-top: -40px;
        width: 317px;
        height: 43px;

  }
    @media (max-width: 480px) {
        text-align: center;
        margin-top: 50px;
        margin-left: 50%;
        transform: translateX(-50%);
        width: 296px;
        height: 43px;
    }
`;

export const Linkedin = styled.img`
    margin-left: 1570px;
    top: -40px;
    width: 32px;
    height: 32px;
    position: relative;

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-left: 1283px;
        width: 32px;
        height: 32px;
  }
    @media (max-width: 480px) {
        margin-left: 50%;
        transform: translateX(-50%);
        top: 50px;
        width: 32px;
        height: 32px;
    }
 `;

export const Oqentregamos = styled(AnchorLink)`
        text-align: left;
        font-size: 16px;
        text-decoration: none;
        color: #FFFFFF;
        opacity: 0.3;
        line-height: 24px;
    
`;

export const Proposta = styled(AnchorLink)`
        text-align: left;
        font-size: 16px;
        text-decoration: none;
        color: #FFFFFF;
        opacity: 0.3;
        line-height: 24px;
`;

export const FaleCaGente = styled.span`
        text-align: left;
        font-size: 16px;
        text-decoration: none;
        color: #FFFFFF;
        opacity: 0.3;
        line-height: 24px;
        cursor: pointer;
`;
export const Trabalhar = styled.a`
        text-align: left;
        font-size: 16px;
        text-decoration: none;
        color: #FFFFFF;
        opacity: 0.3;
        line-height: 24px;
`;
